<template>
  <div>
     
        <div class="row heading_space">
            <div class="news-2-tag">
                <div class="col-md-5 col-sm-5 col-xs-12 top15">
                </div>
                <div class="col-md-7 col-sm-7 col-xs-12 text-right">
                    <div class="social-icons">
                        <h4 :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                            {{ $t('app.share') }}:
                        </h4>

                        <ul class="social_share">
                            <li>
                                <a :href="'https://facebook.com/sharer/sharer.php?u='+share_url" 
                                    target="_blank" 
                                    class="facebook">
                                    <i class="icon-facebook-1"></i>
                                </a>
                            </li>

                            <li>
                                <a :href="'https://twitter.com/share?url='+share_url" 
                                    target="_blank" 
                                    class="twitter">
                                    <i class="icon-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a :href="'https://pinterest.com/pin/create/bookmarklet/?&amp;url='+share_url" 
                                    target="_blank" 
                                    class="pinterest">
                                    <i class="fa fa-pinterest"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
export default {
    name: 'SocialShareCard',
    props: ['share_url'],
    data(){
        return {
            //
        }
    },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
